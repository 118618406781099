import dayjs, { extend } from 'dayjs'
import relativeTimePlugin from 'dayjs/plugin/relativeTime'
import { useQuery } from '@tanstack/vue-query'
import { v4 as uuidv4 } from 'uuid'

export { inflect } from 'inflection'

export {
  useQuery,
  useMutation,
  useInfiniteQuery,
  useQueryClient
} from '@tanstack/vue-query'

export const LONG_DATE_FORMAT = 'dddd, D MMMM YYYY'
export const SHORT_DATE_FORMAT = 'MMM D, YYYY'
export const DATE_TIME_FORMAT = 'dddd, D MMMM YYYY - hh:mm A'

export const PLACEHOLDER_IMAGE = 'https://via.placeholder.com/150'

extend(relativeTimePlugin)

export const formatDate = (date: string, format: 'l' | 's' | 'dt') => {
  const formatMap = {
    l: LONG_DATE_FORMAT,
    s: SHORT_DATE_FORMAT,
    dt: DATE_TIME_FORMAT
  }
  return dayjs(date).format(formatMap[format])
}

export const relativeTime = (date: string) => {
  return dayjs(date).fromNow()
}

export const toPercentage = (value: number) => (value * 100).toFixed(1) + '%'

export const promisify = <T>(val: T) => val

export const enumKeys = <E>(enu: E) =>
  Object.keys(enu as any).filter((key) => isNaN(Number(key))) as (keyof E)[]

export const beforeUnloadListener = (event: BeforeUnloadEvent) => {
  event.preventDefault()
  return (event.returnValue = '')
}

export const exportToWordDoc = (docElement: HTMLElement, title: string) => {
  const docHtml = docElement.innerHTML

  const html = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>${title}</title></head><body>${docHtml}</body></html>`

  const blob = new Blob(['\uFEFF', html], {
    type: 'application/msword'
  })

  // Specify link url
  const url = URL.createObjectURL(blob)

  // Specify file name
  const fileName = `${title}.doc`

  downloadFile(url, fileName)
}

export const downloadFile = (url: string, fileName: string) => {
  // Create download link element
  const downloadLink = document.createElement('a')

  document.body.appendChild(downloadLink)

  // Create a link to the file
  downloadLink.href = url

  // Setting the file name
  downloadLink.download = fileName

  // triggering the function
  downloadLink.click()

  document.body.removeChild(downloadLink)
}

export const useCountries = () => {
  const { axios } = useApi()
  const COUNTRIES_API_URL = `${window.location.protocol}//${window.location.host}/resources/countries.json`

  return useQuery({
    queryFn: async () => {
      const response = await axios.get<CountriesResponse>(COUNTRIES_API_URL)
      const countries = response.data
      countries.EP = 'Europe'
      countries.WO = 'Global'
      return countries
    },
    queryKey: ['countries']
  })
}

export const useInject = <T>(key: string) => {
  const provider = inject<T>(key)

  if (!provider) {
    const message = `${key} provider not found in ${getCurrentInstance()?.type.__name}`
    alert(message)
    throw new Error(message)
  }

  return provider
}

export const useUnsavedChanges = <F extends (...args: any) => any>(
  saver: F
) => {
  onBeforeUnmount(() => {
    removeEventListener('beforeunload', beforeUnloadListener, { capture: true })
  })

  return async (...args: Parameters<F>): Promise<ReturnType<F>> => {
    addEventListener('beforeunload', beforeUnloadListener, { capture: true })
    const result = await saver(...(args as any))
    if (result) {
      removeEventListener('beforeunload', beforeUnloadListener, {
        capture: true
      })
    }
    return result
  }
}

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const getFlag = (countryCode: string) => {
  const flags: Record<string, string> = {
    EP: '/images/flags/europe.png',
    WO: '/images/flags/globe.png'
  }
  return (
    flags[countryCode] ?? `/images/flags/4x3/${countryCode.toLowerCase()}.svg`
  )
}

export const randomUUID = uuidv4

export const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const shuffleArray = (array: unknown[]) => {
  let currentIndex = array.length
  let randomIndex: number

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex]
    ]
  }

  return array
}

export const wait = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })

export const getTextIntersection = (textA: string, textB: string) => {
  const matrix: number[][] = Array(textA.length + 1)
    .fill(null)
    .map(() => Array(textB.length + 1).fill(0))

  let longest = ''

  for (let i = 1; i <= textA.length; i++) {
    for (let j = 1; j <= textB.length; j++) {
      if (textA[i - 1] === textB[j - 1]) {
        matrix[i][j] = matrix[i - 1][j - 1] + 1
        if (matrix[i][j] > longest.length) {
          longest = textA.slice(i - matrix[i][j], i)
        }
      }
    }
  }

  return longest
}

export const getSelectionNodes = (
  range: Range,
  selectors = '.selectable-text'
) => {
  const elements = document.querySelectorAll(selectors)

  const nodes: Record<string, string> = {}

  for (const element of elements) {
    const nodeText = element.innerHTML
    const selectedText = getSelectedTextInElement(element, range)
    if (selectedText) nodes[nodeText] = selectedText
  }

  return nodes
}

export const getSelectedTextInElement = (
  element: Element,
  selectionRange: Range
) => {
  const newRange = selectionRange.cloneRange()
  const range = document.createRange()
  range.selectNodeContents(element)

  if (selectionRange.compareBoundaryPoints(Range.START_TO_START, range) < 0) {
    newRange.setStart(range.startContainer, range.startOffset)
  }
  if (selectionRange.compareBoundaryPoints(Range.END_TO_END, range) > 0) {
    newRange.setEnd(range.endContainer, range.endOffset)
  }

  return newRange.collapsed ? undefined : newRange.toString()
}

export const formatFileSize = (size: number): string => {
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB']
  let index = 0

  while (size >= 1024 && index < units.length - 1) {
    size /= 1024
    index++
  }

  return `${size.toFixed(2)} ${units[index]}`
}
